// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-api-js": () => import("./../../../src/pages/api.js" /* webpackChunkName: "component---src-pages-api-js" */),
  "component---src-pages-awards-js": () => import("./../../../src/pages/awards.js" /* webpackChunkName: "component---src-pages-awards-js" */),
  "component---src-pages-book-us-js": () => import("./../../../src/pages/book-us.js" /* webpackChunkName: "component---src-pages-book-us-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-corporates-js": () => import("./../../../src/pages/corporates.js" /* webpackChunkName: "component---src-pages-corporates-js" */),
  "component---src-pages-customers-js": () => import("./../../../src/pages/customers.js" /* webpackChunkName: "component---src-pages-customers-js" */),
  "component---src-pages-destinations-js": () => import("./../../../src/pages/destinations.js" /* webpackChunkName: "component---src-pages-destinations-js" */),
  "component---src-pages-developers-js": () => import("./../../../src/pages/developers.js" /* webpackChunkName: "component---src-pages-developers-js" */),
  "component---src-pages-embedding-js": () => import("./../../../src/pages/embedding.js" /* webpackChunkName: "component---src-pages-embedding-js" */),
  "component---src-pages-findteam-js": () => import("./../../../src/pages/findteam.js" /* webpackChunkName: "component---src-pages-findteam-js" */),
  "component---src-pages-fr-about-us-js": () => import("./../../../src/pages/fr/about-us.js" /* webpackChunkName: "component---src-pages-fr-about-us-js" */),
  "component---src-pages-fr-api-js": () => import("./../../../src/pages/fr/api.js" /* webpackChunkName: "component---src-pages-fr-api-js" */),
  "component---src-pages-fr-awards-js": () => import("./../../../src/pages/fr/awards.js" /* webpackChunkName: "component---src-pages-fr-awards-js" */),
  "component---src-pages-fr-book-us-js": () => import("./../../../src/pages/fr/book-us.js" /* webpackChunkName: "component---src-pages-fr-book-us-js" */),
  "component---src-pages-fr-careers-js": () => import("./../../../src/pages/fr/careers.js" /* webpackChunkName: "component---src-pages-fr-careers-js" */),
  "component---src-pages-fr-corporates-js": () => import("./../../../src/pages/fr/corporates.js" /* webpackChunkName: "component---src-pages-fr-corporates-js" */),
  "component---src-pages-fr-customers-js": () => import("./../../../src/pages/fr/customers.js" /* webpackChunkName: "component---src-pages-fr-customers-js" */),
  "component---src-pages-fr-destinations-js": () => import("./../../../src/pages/fr/destinations.js" /* webpackChunkName: "component---src-pages-fr-destinations-js" */),
  "component---src-pages-fr-developers-js": () => import("./../../../src/pages/fr/developers.js" /* webpackChunkName: "component---src-pages-fr-developers-js" */),
  "component---src-pages-fr-embedding-js": () => import("./../../../src/pages/fr/embedding.js" /* webpackChunkName: "component---src-pages-fr-embedding-js" */),
  "component---src-pages-fr-index-js": () => import("./../../../src/pages/fr/index.js" /* webpackChunkName: "component---src-pages-fr-index-js" */),
  "component---src-pages-fr-parsing-js": () => import("./../../../src/pages/fr/parsing.js" /* webpackChunkName: "component---src-pages-fr-parsing-js" */),
  "component---src-pages-fr-partners-js": () => import("./../../../src/pages/fr/partners.js" /* webpackChunkName: "component---src-pages-fr-partners-js" */),
  "component---src-pages-fr-press-js": () => import("./../../../src/pages/fr/press.js" /* webpackChunkName: "component---src-pages-fr-press-js" */),
  "component---src-pages-fr-pricing-js": () => import("./../../../src/pages/fr/pricing.js" /* webpackChunkName: "component---src-pages-fr-pricing-js" */),
  "component---src-pages-fr-scoring-js": () => import("./../../../src/pages/fr/scoring.js" /* webpackChunkName: "component---src-pages-fr-scoring-js" */),
  "component---src-pages-fr-searching-js": () => import("./../../../src/pages/fr/searching.js" /* webpackChunkName: "component---src-pages-fr-searching-js" */),
  "component---src-pages-fr-signup-js": () => import("./../../../src/pages/fr/signup.js" /* webpackChunkName: "component---src-pages-fr-signup-js" */),
  "component---src-pages-fr-sources-js": () => import("./../../../src/pages/fr/sources.js" /* webpackChunkName: "component---src-pages-fr-sources-js" */),
  "component---src-pages-fr-tagging-js": () => import("./../../../src/pages/fr/tagging.js" /* webpackChunkName: "component---src-pages-fr-tagging-js" */),
  "component---src-pages-fr-upskilling-js": () => import("./../../../src/pages/fr/upskilling.js" /* webpackChunkName: "component---src-pages-fr-upskilling-js" */),
  "component---src-pages-fr-use-cases-js": () => import("./../../../src/pages/fr/use-cases.js" /* webpackChunkName: "component---src-pages-fr-use-cases-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-parsing-js": () => import("./../../../src/pages/parsing.js" /* webpackChunkName: "component---src-pages-parsing-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-scoring-js": () => import("./../../../src/pages/scoring.js" /* webpackChunkName: "component---src-pages-scoring-js" */),
  "component---src-pages-searching-js": () => import("./../../../src/pages/searching.js" /* webpackChunkName: "component---src-pages-searching-js" */),
  "component---src-pages-signin-js": () => import("./../../../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-sources-js": () => import("./../../../src/pages/sources.js" /* webpackChunkName: "component---src-pages-sources-js" */),
  "component---src-pages-tagging-js": () => import("./../../../src/pages/tagging.js" /* webpackChunkName: "component---src-pages-tagging-js" */),
  "component---src-pages-upskilling-js": () => import("./../../../src/pages/upskilling.js" /* webpackChunkName: "component---src-pages-upskilling-js" */),
  "component---src-pages-use-cases-js": () => import("./../../../src/pages/use-cases.js" /* webpackChunkName: "component---src-pages-use-cases-js" */)
}

